import SmoothScroll from "smooth-scroll";

var scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true,
});

// MicroModal初期化
MicroModal.init();

// ユーザーの流入元を取得
const userMedia = () => {
  const url = new URL(window.location.href);
  const params = url.searchParams;
  const media = params.get('media');

  return media !== null ? media : 'not_found';
};

// ステップフォームの処理
function stepFormControl() {
  // フォームの進捗表示
  const formProgress01 = document.querySelector(".progress-step-01");
  const formProgress02 = document.querySelector(".progress-step-02");
  const formProgress03 = document.querySelector(".progress-step-03");
  // フォームの画面
  const formStep01 = document.querySelector(".stepform__input-screen--step-01");
  const formStep02 = document.querySelector(".stepform__input-screen--step-02");
  const formStep03 = document.querySelector(".stepform__input-screen--step-03");
  const formStep04 = document.querySelector(".stepform__input-screen--step-04");
  // 次へボタンの取得
  const formButtonNext01 = document.querySelector(".stepform__next--01");
  const formButtonNext02 = document.querySelector(".stepform__next--02");
  const formButtonNext03 = document.querySelector(".stepform__next--03");
  // 送信ボタン
  const formButtonSend = document.querySelector(".button-next--step-send");
  // 戻るボタンの取得
  const formButtonReverse02 = document.querySelector(".stepform__reverse--02");
  const formButtonReverse03 = document.querySelector(".stepform__reverse--03");
  const formButtonReverse04 = document.querySelector(".stepform__reverse--04");
  // フォームのvalue取得
  const formPrefectureStep = document.getElementsByName("form_prefecture_step")[0]; // 都道府県
  const formAgeStep = document.getElementsByName("form_age_step")[0]; // 年齢
  const formNameStep = document.getElementsByName("form_name_step")[0]; // お名前
  const formSexStep = () => {
    let value = "";
    const radiobutton = Array.from(document.getElementsByName("form_sex_step"));

    radiobutton.forEach(elm => {
      if (elm.checked) {
        value = elm.value;
      };
    });

    return value;
  }; // 性別
  const formTel01Step = document.getElementsByName("form_tel_01_step")[0]; // 電話番号01
  const formTel02Step = document.getElementsByName("form_tel_02_step")[0]; // 電話番号02
  const formTel03Step = document.getElementsByName("form_tel_03_step")[0]; // 電話番号03
  const formMailStep = document.getElementsByName("form_email_step")[0]; // メールアドレス
  // エラーメッセージの管理
  const errorPrefecture = document.querySelector(".form-error--prefecture-step");
  const errorAge = document.querySelector(".form-error--age-step");
  const errorName = document.querySelector(".form-error--name-step");
  const errorSex = document.querySelector(".form-error--sex-step");
  const errorTel = document.querySelector(".form-error--tel-step");
  const errorEmail = document.querySelector(".form-error--mail-step");
  // 確認画面の入力
  const formConfirmPrefectureStep = document.getElementById("form-confirm-prefecture-step");
  const formConfirmAgeStep = document.getElementById("form-confirm-age-step");
  const formConfirmNameStep = document.getElementById("form-confirm-name-step");
  const formConfirmSexStep = document.getElementById("form-confirm-sex-step");
  const formConfirmTelStep = document.getElementById("form-confirm-tel-step");
  const formConfirmMailStep = document.getElementById("form-confirm-mail-step");

  // ステップ1の「次へ」処理
  formButtonNext01.addEventListener("click", () => {
    let prefectureCheck = false;
    let ageCheck = false;

    // 都道府県の入力確認
    if (formPrefectureStep.value != "") {
      errorPrefecture.classList.remove("active");
      formConfirmPrefectureStep.innerHTML = formPrefectureStep.value;
      prefectureCheck = true;
    } else {
      errorPrefecture.classList.add("active");
      prefectureCheck = false;
    }

    // 年齢の入力確認
    if (formAgeStep.value != "") {
      errorAge.classList.remove("active");
      formConfirmAgeStep.innerHTML = formAgeStep.value;
      ageCheck = true;
    } else {
      errorAge.classList.add("active");
      ageCheck = false;
    }

    // 次に進むの処理
    if (prefectureCheck && ageCheck) {
      formStep01.classList.remove("active");
      formStep02.classList.add("active");
      formProgress01.classList.remove("active");
      formProgress02.classList.add("active");
    } else {
      return;
    }
  });

  // ステップ2の「次へ」処理
  formButtonNext02.addEventListener("click", () => {
    let nameCheck = false;
    let sexCheck = false;

    // 名前の入力
    if (formNameStep.value != "") {
      errorName.classList.remove("active");
      formConfirmNameStep.innerHTML = formNameStep.value;
      nameCheck = true;
    } else {
      errorName.classList.add("active");
      nameCheck = false;
    }

    // 性別の入力
    if (formSexStep() != "") {
      errorSex.classList.remove("active");
      formConfirmSexStep.innerHTML = formSexStep();
      sexCheck = true;
    } else {
      errorSex.classList.add("active");
      sexCheck = false;
    }

    // 次に進むの処理
    if (nameCheck && sexCheck) {
      formStep02.classList.remove("active");
      formStep03.classList.add("active");
      formProgress02.classList.remove("active");
      formProgress03.classList.add("active");
    } else {
      return;
    }
  });

  // ステップ3の「次へ」処理
  formButtonNext03.addEventListener("click", () => {
    let telCheck = false;
    let mailCheck = false;

    // メールアドレスの正規表現
    const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // 電話の入力
    if (formTel01Step.value != "" && formTel02Step.value != "" && formTel03Step.value != "") {
      errorTel.classList.remove("active");
      formConfirmTelStep.innerHTML = `${formTel01Step.value}-${formTel02Step.value}-${formTel03Step.value}`;
      telCheck = true;
    } else {
      errorTel.classList.add("active");
      telCheck = false;
    }

    // メールアドレスのバリデーション
    if (formMailStep.value != "" && regExp.test(formMailStep.value)) {
      errorEmail.classList.remove("active");
      formConfirmMailStep.innerText = formMailStep.value;
      mailCheck = true;
    } else if (!regExp.test(formMailStep.value) && formMailStep.value != "") {
      errorEmail.innerHTML = "正しいメールアドレスを入力してください";
      errorEmail.classList.add("active");
      mailCheck = false;
    } else {
      errorEmail.innerHTML = "入力してください";
      errorEmail.classList.add("active");
      mailCheck = false;
    }

    // 次に進むの処理
    if (telCheck && mailCheck) {
      formStep03.classList.remove("active");
      formStep04.classList.add("active");
    } else {
      return;
    }
  });

  // ステップ2の「戻る」の処理
  formButtonReverse02.addEventListener("click", () => {
    formStep02.classList.remove("active");
    formStep01.classList.add("active");
    formProgress02.classList.remove("active");
    formProgress01.classList.add("active");
  });

  // ステップ3の「戻る」の処理
  formButtonReverse03.addEventListener("click", () => {
    formStep03.classList.remove("active");
    formStep02.classList.add("active");
    formProgress03.classList.remove("active");
    formProgress02.classList.add("active");
  });

  // ステップ4の「戻る」の処理
  formButtonReverse04.addEventListener("click", () => {
    formStep04.classList.remove("active");
    formStep03.classList.add("active");
  });

  // 送信の処理
  formButtonSend.addEventListener("click", (elm) => {
    elm.target.setAttribute("disabled", "");
    elm.target.innerText = "送信中です";

    // 送信内容の定義
    const userPrefecture = formConfirmPrefectureStep.innerHTML;
    const userAge = formConfirmAgeStep.innerHTML;
    const userName = formConfirmNameStep.innerHTML;
    const userSex = formConfirmSexStep.innerHTML;
    const userTel = formConfirmTelStep.innerHTML;
    const userEmail = formConfirmMailStep.innerHTML;
    const media = userMedia();

    sendGas(userPrefecture, userAge, userName, userSex, userTel, userEmail, media);
    sendmail(userPrefecture, userAge, userName, userSex, userTel, userEmail, media);
  });
}

// 成功体験のスライド
function successSlide() {
  // 矢印の取得
  const prevArrow = document.querySelector(".success__slider-arrow--prev");
  const nextArrow = document.querySelector(".success__slider-arrow--next");

  const slide = new Splide("#success__slider", {
    type: "loop",
    arrows: false,
    pagination: false,
    gap: "3.75rem",
    mediaQuery: "min",
    breakpoints: {
      580: {
        width: "85%"
      }
    }
  });

  slide.mount();

  prevArrow.addEventListener("click", () => {
    slide.go("<");
  });

  nextArrow.addEventListener("click", () => {
    slide.go(">");
  });
}

// 事例紹介のスライド
function achievementSlider() {
  const slideTop = new Splide("#achievement__slider--top", {
    type: "loop",
    arrows: false,
    pagination: false,
    autoWidth: true,
    drag: false,
    autoScroll: {
      speed: 2.5,
      pauseOnHover: false
    },
  });

  const slideBottom = new Splide("#achievement__slider--bottom", {
    type: "loop",
    arrows: false,
    pagination: false,
    autoWidth: true,
    drag: false,
    autoScroll: {
      speed: 2.5,
      pauseOnHover: false
    },
  });

  slideTop.mount(window.splide.Extensions);
  slideBottom.mount(window.splide.Extensions);

  slideResize();

  function slideResize() {
    const image = document.querySelector(".achievement__logo .image img");
    const targetSlide = document.getElementById("achievement__slider--bottom");

    targetSlide.style.width = `${targetSlide.clientWidth + (image.clientWidth / 2)}px`;
    targetSlide.style.transform = `translateX(${(image.clientWidth / 2) * -1}px)`;
  }
}

// 2ヶ月後を表示する
function ctaInit2monthLater() {
  const targetText = document.querySelectorAll(".cta-button__heading .autofill-month");
  const date = new Date();
  date.setMonth(date.getMonth() + 2);
  const month = date.getMonth() + 1;

  targetText.forEach(elm => {
    elm.innerHTML = `${month}月入社`;
  });
}

// メンバー紹介スライダー
function memberSlider() {
  // 矢印の取得
  const prevArrow = document.querySelector(".member__slider-arrow--prev");
  const nextArrow = document.querySelector(".member__slider-arrow--next");

  const slider = new Splide("#member__slider", {
    type: "loop",
    arrows: false,
    pagination: false,
    gap: "2rem",
    mediaQuery: "min",
    breakpoints: {
      580: {
        width: "85%"
      }
    }
  });

  slider.mount();

  prevArrow.addEventListener("click", () => {
    slider.go("<");
  });

  nextArrow.addEventListener("click", () => {
    slider.go(">");
  });
}

// function mainForm() {
//   // フォームの取得
//   const contactMainForm = document.querySelector(".contact__form-main");
//   const contactFormConfirm = document.querySelector(".contact__form-confirm");
//   // 次へボタンの取得
//   const nextButton = document.querySelector(".contact__next");
//   // 戻るボタンの取得
//   const prevButton = document.querySelector(".contact__prev");
//   // 送信ボタンの取得
//   const sendButton = document.querySelector(".contact__send");
//   // フォームのvalue取得
//   const formPrefecture = document.getElementsByName("form_prefecture")[0]; // 都道府県
//   const formAge = document.getElementsByName("form_age")[0]; // 年齢
//   const formName = document.getElementsByName("form_name")[0]; // お名前
//   const formSex = () => {
//     let value = "";
//     const radiobutton = Array.from(document.getElementsByName("form_sex"));

//     radiobutton.forEach(elm => {
//       if (elm.checked) {
//         value = elm.value;
//       };
//     });

//     return value;
//   }; // 性別
//   const formTel01 = document.getElementsByName("form_tel_01")[0]; // 電話番号01
//   const formTel02 = document.getElementsByName("form_tel_02")[0]; // 電話番号02
//   const formTel03 = document.getElementsByName("form_tel_03")[0]; // 電話番号03
//   const formMail = document.getElementsByName("form_email")[0]; // メールアドレス
//   // エラーメッセージの管理
//   const errorPrefecture = document.querySelector(".form-error--prefecture");
//   const errorAge = document.querySelector(".form-error--age");
//   const errorName = document.querySelector(".form-error--name");
//   const errorSex = document.querySelector(".form-error--sex");
//   const errorTel = document.querySelector(".form-error--tel");
//   const errorEmail = document.querySelector(".form-error--mail");
//   // 確認画面の入力
//   const formConfirmPrefecture = document.getElementById("form-confirm-prefecture");
//   const formConfirmAge = document.getElementById("form-confirm-age");
//   const formConfirmName = document.getElementById("form-confirm-name");
//   const formConfirmSex = document.getElementById("form-confirm-sex");
//   const formConfirmTel = document.getElementById("form-confirm-tel");
//   const formConfirmMail = document.getElementById("form-confirm-mail");

//   nextButton.addEventListener("click", () => {
//     // フォームの状態管理
//     let prefectureCheck = false;
//     let ageCheck = false;
//     let nameCheck = false;
//     let sexCheck = false;
//     let telCheck = false;
//     let mailCheck = false;

//     // メールアドレスの正規表現
//     const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//     // 都道府県の入力確認
//     if (formPrefecture.value != "") {
//       errorPrefecture.classList.remove("active");
//       formConfirmPrefecture.innerHTML = formPrefecture.value;
//       prefectureCheck = true;
//     } else {
//       errorPrefecture.classList.add("active");
//       prefectureCheck = false;
//     }

//     // 年齢の入力確認
//     if (formAge.value != "") {
//       errorAge.classList.remove("active");
//       formConfirmAge.innerHTML = formAge.value;
//       ageCheck = true;
//     } else {
//       errorAge.classList.add("active");
//       ageCheck = false;
//     }

//     // 名前の入力
//     if (formName.value != "") {
//       errorName.classList.remove("active");
//       formConfirmName.innerHTML = formName.value;
//       nameCheck = true;
//     } else {
//       errorName.classList.add("active");
//       nameCheck = false;
//     }

//     // 性別の入力
//     if (formSex() != "") {
//       errorSex.classList.remove("active");
//       formConfirmSex.innerHTML = formSex();
//       sexCheck = true;
//     } else {
//       errorSex.classList.add("active");
//       sexCheck = false;
//     }

//     // 電話の入力
//     if (formTel01.value != "" && formTel02.value != "" && formTel03.value != "") {
//       errorTel.classList.remove("active");
//       formConfirmTel.innerHTML = `${formTel01.value}-${formTel02.value}-${formTel03.value}`;
//       telCheck = true;
//     } else {
//       errorTel.classList.add("active");
//       telCheck = false;
//     }

//     // メールアドレスのバリデーション
//     if (formMail.value != "" && regExp.test(formMail.value)) {
//       errorEmail.classList.remove("active");
//       formConfirmMail.innerText = formMail.value;
//       mailCheck = true;
//     } else if (!regExp.test(formMail.value) && formMail.value != "") {
//       errorEmail.innerHTML = "正しいメールアドレスを入力してください";
//       errorEmail.classList.add("active");
//       mailCheck = false;
//     } else {
//       errorEmail.innerHTML = "入力してください";
//       errorEmail.classList.add("active");
//       mailCheck = false;
//     }

//     if (prefectureCheck && ageCheck && nameCheck && sexCheck && telCheck && mailCheck) {
//       contactMainForm.classList.remove("active");
//       contactFormConfirm.classList.add("active");
//     }
//   });

//   prevButton.addEventListener("click", () => {
//     contactFormConfirm.classList.remove("active");
//     contactMainForm.classList.add("active");
//   });

//   sendButton.addEventListener("click", (elm) => {
//     elm.target.setAttribute("disabled", "");
//     elm.target.innerText = "送信中です";

//     // 送信内容の定義
//     const userPrefecture = formConfirmPrefecture.innerHTML;
//     const userAge = formConfirmAge.innerHTML;
//     const userName = formConfirmName.innerHTML;
//     const userSex = formConfirmSex.innerHTML;
//     const userTel = formConfirmTel.innerHTML;
//     const userEmail = formConfirmMail.innerHTML;
//     const media = userMedia();

//     sendGas(userPrefecture, userAge, userName, userSex, userTel, userEmail, media);
//     sendmail(userPrefecture, userAge, userName, userSex, userTel, userEmail, media);
//   });
// }

// Contact
function stepContactControl() {
  console.log("stepContactControl");
  // フォームの進捗表示
  const formProgress01 = document.querySelector(".contact-progress-step-01");
  const formProgress02 = document.querySelector(".contact-progress-step-02");
  const formProgress03 = document.querySelector(".contact-progress-step-03");
  // フォームの画面
  const formStep01 = document.querySelector(".contact-stepform__input-screen--step-01");
  const formStep02 = document.querySelector(".contact-stepform__input-screen--step-02");
  const formStep03 = document.querySelector(".contact-stepform__input-screen--step-03");
  const formStep04 = document.querySelector(".contact-stepform__input-screen--step-04");
  // 次へボタンの取得
  const formButtonNext01 = document.querySelector(".contact-stepform__next--01");
  const formButtonNext02 = document.querySelector(".contact-stepform__next--02");
  const formButtonNext03 = document.querySelector(".contact-stepform__next--03");
  // 送信ボタン
  const formButtonSend = document.querySelector(".contact-button-next--step-send");
  // 戻るボタンの取得
  const formButtonReverse02 = document.querySelector(".contact-stepform__reverse--02");
  const formButtonReverse03 = document.querySelector(".contact-stepform__reverse--03");
  const formButtonReverse04 = document.querySelector(".contact-stepform__reverse--04");
  // フォームのvalue取得
  const formPrefectureStep = document.getElementsByName("contact-form_prefecture_step")[0]; // 都道府県
  const formAgeStep = document.getElementsByName("contact-form_age_step")[0]; // 年齢
  const formNameStep = document.getElementsByName("contact-form_name_step")[0]; // お名前
  const formSexStep = () => {
    let value = "";
    const radiobutton = Array.from(document.getElementsByName("contact-form_sex_step"));

    radiobutton.forEach(elm => {
      if (elm.checked) {
        value = elm.value;
      };
    });

    return value;
  }; // 性別
  const formTel01Step = document.getElementsByName("contact-form_tel_01_step")[0]; // 電話番号01
  const formTel02Step = document.getElementsByName("contact-form_tel_02_step")[0]; // 電話番号02
  const formTel03Step = document.getElementsByName("contact-form_tel_03_step")[0]; // 電話番号03
  const formMailStep = document.getElementsByName("contact-form_email_step")[0]; // メールアドレス
  // エラーメッセージの管理
  const errorPrefecture = document.querySelector(".contact-form-error--prefecture-step");
  const errorAge = document.querySelector(".contact-form-error--age-step");
  const errorName = document.querySelector(".contact-form-error--name-step");
  const errorSex = document.querySelector(".contact-form-error--sex-step");
  const errorTel = document.querySelector(".contact-form-error--tel-step");
  const errorEmail = document.querySelector(".contact-form-error--mail-step");
  // 確認画面の入力
  const formConfirmPrefectureStep = document.getElementById("contact-form-confirm-prefecture-step");
  const formConfirmAgeStep = document.getElementById("contact-form-confirm-age-step");
  const formConfirmNameStep = document.getElementById("contact-form-confirm-name-step");
  const formConfirmSexStep = document.getElementById("contact-form-confirm-sex-step");
  const formConfirmTelStep = document.getElementById("contact-form-confirm-tel-step");
  const formConfirmMailStep = document.getElementById("contact-form-confirm-mail-step");

  // ステップ1の「次へ」処理
  formButtonNext01.addEventListener("click", () => {
    console.log("Click")
    let prefectureCheck = false;
    let ageCheck = false;

    // 都道府県の入力確認
    if (formPrefectureStep.value != "") {
      errorPrefecture.classList.remove("active");
      formConfirmPrefectureStep.innerHTML = formPrefectureStep.value;
      prefectureCheck = true;
    } else {
      errorPrefecture.classList.add("active");
      prefectureCheck = false;
    }

    // 年齢の入力確認
    if (formAgeStep.value != "") {
      errorAge.classList.remove("active");
      formConfirmAgeStep.innerHTML = formAgeStep.value;
      ageCheck = true;
    } else {
      errorAge.classList.add("active");
      ageCheck = false;
    }

    // 次に進むの処理
    if (prefectureCheck && ageCheck) {
      formStep01.classList.remove("active");
      formStep02.classList.add("active");
      formProgress01.classList.remove("active");
      formProgress02.classList.add("active");
    } else {
      return;
    }
  });

  // ステップ2の「次へ」処理
  formButtonNext02.addEventListener("click", () => {
    let nameCheck = false;
    let sexCheck = false;

    // 名前の入力
    if (formNameStep.value != "") {
      errorName.classList.remove("active");
      formConfirmNameStep.innerHTML = formNameStep.value;
      nameCheck = true;
    } else {
      errorName.classList.add("active");
      nameCheck = false;
    }

    // 性別の入力
    if (formSexStep() != "") {
      errorSex.classList.remove("active");
      formConfirmSexStep.innerHTML = formSexStep();
      sexCheck = true;
    } else {
      errorSex.classList.add("active");
      sexCheck = false;
    }

    // 次に進むの処理
    if (nameCheck && sexCheck) {
      formStep02.classList.remove("active");
      formStep03.classList.add("active");
      formProgress02.classList.remove("active");
      formProgress03.classList.add("active");
    } else {
      return;
    }
  });

  // ステップ3の「次へ」処理
  formButtonNext03.addEventListener("click", () => {
    let telCheck = false;
    let mailCheck = false;

    // メールアドレスの正規表現
    const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // 電話の入力
    if (formTel01Step.value != "" && formTel02Step.value != "" && formTel03Step.value != "") {
      errorTel.classList.remove("active");
      formConfirmTelStep.innerHTML = `${formTel01Step.value}-${formTel02Step.value}-${formTel03Step.value}`;
      telCheck = true;
    } else {
      errorTel.classList.add("active");
      telCheck = false;
    }

    // メールアドレスのバリデーション
    if (formMailStep.value != "" && regExp.test(formMailStep.value)) {
      errorEmail.classList.remove("active");
      formConfirmMailStep.innerText = formMailStep.value;
      mailCheck = true;
    } else if (!regExp.test(formMailStep.value) && formMailStep.value != "") {
      errorEmail.innerHTML = "正しいメールアドレスを入力してください";
      errorEmail.classList.add("active");
      mailCheck = false;
    } else {
      errorEmail.innerHTML = "入力してください";
      errorEmail.classList.add("active");
      mailCheck = false;
    }

    console.log(formMailStep.value);

    // 次に進むの処理
    if (telCheck && mailCheck) {
      formStep03.classList.remove("active");
      formStep04.classList.add("active");
    } else {
      return;
    }
  });

  // ステップ2の「戻る」の処理
  formButtonReverse02.addEventListener("click", () => {
    formStep02.classList.remove("active");
    formStep01.classList.add("active");
    formProgress02.classList.remove("active");
    formProgress01.classList.add("active");
  });

  // ステップ3の「戻る」の処理
  formButtonReverse03.addEventListener("click", () => {
    formStep03.classList.remove("active");
    formStep02.classList.add("active");
    formProgress03.classList.remove("active");
    formProgress02.classList.add("active");
  });

  // ステップ4の「戻る」の処理
  formButtonReverse04.addEventListener("click", () => {
    formStep04.classList.remove("active");
    formStep03.classList.add("active");
  });

  // 送信の処理
  formButtonSend.addEventListener("click", (elm) => {
    elm.target.setAttribute("disabled", "");
    elm.target.innerText = "送信中です";

    // 送信内容の定義
    const userPrefecture = formConfirmPrefectureStep.innerHTML;
    const userAge = formConfirmAgeStep.innerHTML;
    const userName = formConfirmNameStep.innerHTML;
    const userSex = formConfirmSexStep.innerHTML;
    const userTel = formConfirmTelStep.innerHTML;
    const userEmail = formConfirmMailStep.innerHTML;
    const media = userMedia();

    sendGas(userPrefecture, userAge, userName, userSex, userTel, userEmail, media);
    sendmail(userPrefecture, userAge, userName, userSex, userTel, userEmail, media);
  });
}

function sendGas(user_prefecture, user_age, user_name, user_sex, user_tel, user_mail, media) {
  const url = './app/gas.php';

  $.ajax(url, {
    type: 'post',
    data: {
      'user_prefecture': user_prefecture,
      'user_age': user_age,
      'user_name': user_name,
      'user_sex': user_sex,
      'user_tel': user_tel,
      'user_mail': user_mail,
      'user_media': media,
    },
    dataType: 'json'
  }).done((data) => {
    console.log(data.parameter);
  });
}

function sendmail(user_prefecture, user_age, user_name, user_sex, user_tel, user_mail, media) {
  const url = './app/sendmail.php';

  $.ajax(url, {
    type: 'post',
    data: {
      'user_prefecture': user_prefecture,
      'user_age': user_age,
      'user_name': user_name,
      'user_sex': user_sex,
      'user_tel': user_tel,
      'user_mail': user_mail,
      'user_media': media,
    },
    dataType: 'text'
  }).done((data) => {
    window.location.href = './thanks/';
  });
}

window.addEventListener("load", () => {
  stepFormControl();
  successSlide();
  achievementSlider();
  ctaInit2monthLater();
  memberSlider();
  stepContactControl();
  // mainForm();
});